import React, { Component } from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import PageSection from '../components/page-section';
import SectionTitle from '../components/section-title';
import { graphql } from 'gatsby';
import { Row, Col } from '../components/grid'
import ContactForm from '../components/contact-form';
import Image from '../components/image';
import SEO from '../components/seo'
import Button from '../components/form/button';
import theme from '../theme';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const ImageBox = styled.div`
	height: auto;
	margin-bottom: 20px;

	@media screen and (min-width: 768px) {
		max-height: 400px;
	}
	@media screen and (min-width: 950px) {
		max-height: 600px;
	}
`;

class ContactPage extends Component {
	render () {
		return (
            <Layout>
				<SEO
					title={'Kontakta oss'}
					description={`Välkommen till oss på Admentum. Här hittar du alla våra kontaktuppgifter.`}
				/>

				<PageSection>
					<SectionTitle
						title="Kontakta oss"
						center
					/>
					<Row>
						<Col width={{sm: 1, md:3/3}}
						center>
							<p>
								Välkommen till oss på Admentum. Här hittar du kontaktuppgifter till alla vi som jobbar här. Varmt välkommen att höra av dig!
							</p>
							<AnchorLink href='#demo'>
								<Button
									color="#666"
									hoverColor="#fff"
									center
								>
									Jag vill ha en personlig visning
								</Button>
							</AnchorLink>
						</Col>
					</Row>
				</PageSection>
				<PageSection>

					<Row>
					<Col width={{sm: 1, md:2/3}} center>
						<div class="map-responsive">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.8214121093383!2d18.049547515893597!3d59.33593538166114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d1ffd86ff41%3A0xae5cead4d3dcd917!2sADMENTUM!5e0!3m2!1ssv!2sse!4v1582712851452!5m2!1ssv!2sse" width="600" height="450" frameborder="0" title="Karta" allowfullscreen=""></iframe>
						</div>
					</Col>

					<Col width={{sm: 1, md:1/3}}
					center>

			<p>
			<h4>Kontaktuppgifter</h4>
			Admentum AB<br />
			Sickla industriväg 6<br />
			132 54 Nacka
			</p>
			<p>
				<b>E-post:</b> <a href="mailto:info@admentum.se">info@admentum.se</a>
			</p>
					</Col>
					</Row>

				</PageSection>

				<PageSection>
					<SectionTitle
						title="Admentums support"
						center
					/>

					<Row>
						<Col width={{sm: 1, md:1/1}}>
							<p>
								Ibland är det inte rätt eller man har glömt bort lösenord eller användarnamn.
								Inga problem; det löser vi på Admentum!!
							</p>
							<h4>Jag har tappat bort mitt lösenord!</h4>
							<p>
							Om du tappat bort eller glömt till lösenord till Admentum så finns det en jättesmidig
							funktion för att skapa ett nytt lösenord. Gå till skola.admentum.se och Klicka på
							”Glömt lösenord”. Skriv in de e-postadress du har registrerad på ditt Admentum konto.
							Du får nu ett mail från oss med en länk där du kan skapa ett nytt lösenord.
							OBS! I detta mail står det även vad du har för användarnamn om du behöver påminnas.
							Tänk även på att vissa telefoner och webbläsare korrigerar ord så att de får versaler
							även om du inte skrev det i inloggningsfönstret.
							</p>
							<p>
							Om du inte får ett mail från Admentum så har skolan inte har en e-post
							eller en gammal e-post registrerad på dig- vänd dig då till skolan
							så kan du få hjälp av skolans administratör. Vi på Admentum kan tyvärr inte hjälpa dig med detta.
							</p>
						</Col>
					</Row>
				</PageSection>

				<PageSection>
					<Row>
						<Col width={{sm: 1, md:1/2}}>
						<h3>Support för lärare</h3>
							<p>
								Du som är lärare och använder Admentum ska alltid vända dig till skolans administratör
								för att få hjälp. Om du glömt dina inloggningsuppgifter eller vill ha annan guidning
								i systemet så är det alltid administratören som är din kontaktyta. Det finns även
								användarguider i systemet. När du loggat in så finner du även Admentums hjälpsektion
								uppe i högra hörnet genom att klicka på frågetecknet (?):
								<a href="https://skola.admentum.se/help/index"> https://skola.admentum.se/help/index. </a> </p>
								<p>
								Vi på Admentum kan inte hjälpa dig med supportfrågor.
							</p>
						</Col>
						<Col width={{sm: 1, md:1/2}}>
						<h3>Support för vårdnadshavare och elever</h3>
							<p>
								Är du elev eller vårdnadshavare är det alltid skolan administratör du ska vända dig
								till för att få hjälp och vägledning i systemet. Det finns även användarguider i systemet.
								När du loggat in så finner du även Admentums hjälpsektion uppe i högra hörnet genom
								att klicka på frågetecknet (?): <a href="https://skola.admentum.se/help/index"> https://skola.admentum.se/help/index.</a>
							</p>
							<p>
								Vi på Admentum kan tyvärr inte hjälpa dig med supportfrågor.
							</p>
						</Col>
					</Row>
					<Row>
						<Col width={{sm: 1, md:1/2}}>
						<h3>Support för superadministratörer</h3>
							<p>
								Varje skola har en superadministratör som har tillgång till Admentums support.
								Nedan ser du kontaktinformation.
							</p>
							<p>
							Öppettider: mån-fre 8:30-17:00 <br/>
							e-post: <a href="mailto:support@admentum.se">support@admentum.se</a>
							</p>
						</Col>
					</Row>
				</PageSection>
			</Layout>
        );
	}
}

export default ContactPage
export const pageQuery = graphql`query SchoolContactQuery {
  appImage: file(relativePath: {eq: "app.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  screenImage: file(relativePath: {eq: "screen-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  hakan: file(relativePath: {eq: "hakan_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  christian: file(relativePath: {eq: "christian_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  sara: file(relativePath: {eq: "sara_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  andreas: file(relativePath: {eq: "andreas_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  simon: file(relativePath: {eq: "simon_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  matilda: file(relativePath: {eq: "matilda_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  admentum_ganget: file(relativePath: {eq: "admentum_ganget2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  screenImage2: file(relativePath: {eq: "screen-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`
